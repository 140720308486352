.airsys-modal {
    .modal-header {
        background: #ff9503;
        border-top-left-radius: $border-radius-base;
        border-top-right-radius: $border-radius-base;

        .modal-title {
            text-align: left;
            color: #fff;
            text-transform: none;
        }
    }
}

.table-airsys {
    thead {
        background-color: #ff9503;
    }

    &.table-bordered > thead > tr > th,
    &.table-bordered > thead > tr > td,
    // &.table-bordered > tbody > tr > th,
    // &.table-bordered > tbody > tr > td,
    //&.table-bordered > tfoot > tr > th,
    //&.table-bordered > tfoot > tr > td
    {
        border-color: #FBCA88;
        color: #fff;
    }
}

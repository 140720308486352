.btn-shadow {
    box-shadow: 0px 10px 40px -15px rgba(0, 0, 0, 0.67);
}

.btn-round {
    border-radius: 60px;
}

.btn-xl {
    font-size: 20px;
    padding: 8px 60px;
}

.btn-orange {
    @include button-variant(#fff, #ff9503, #ff9503);
}

.btn-orange-dropdown {
    color: #fff;
    background-color: #ff9503;
    border-color: #ff9503;
    font-size: 20px;
}

.btn-orange-dropdown:focus,
.btn-orange-dropdown.focus {
    color: #fff;
    background-color: #ff9503;
    border-color: #ff9503;
}

.btn-orange-dropdown:hover {
    color: #fff;
    background-color: #ff9503;
    border-color: #ff9503;
}

.btn-orange-dropdown:active,
.btn-orange-dropdown.active,
.open > .btn-orange-dropdown.dropdown-toggle {
    color: #fff;
    background-color: #ff9503;
    border-color: #ff9503;
}

.btn-orange-dropdown:active:hover,
.btn-orange-dropdown:active:focus,
.btn-orange-dropdown:active.focus,
.btn-orange-dropdown.active:hover,
.btn-orange-dropdown.active:focus,
.btn-orange-dropdown.active.focus,
.open > .btn-orange-dropdown.dropdown-toggle:hover,
.open > .btn-orange-dropdown.dropdown-toggle:focus,
.open > .btn-orange-dropdown.dropdown-toggle.focus {
    color: #fff;
    background-color: #ff9503;
    border-color: #ff9503;
}

.btn-orange-dropdown:active,
.btn-orange-dropdown.active,
.open > .btn-orange-dropdown.dropdown-toggle {
    background-image: none;
}

.btn-orange-dropdown.disabled:hover,
.btn-orange-dropdown.disabled:focus,
.btn-orange-dropdown.disabled.focus,
.btn-orange-dropdown[disabled]:hover,
.btn-orange-dropdown[disabled]:focus,
.btn-orange-dropdown[disabled].focus,
fieldset[disabled] .btn-orange-dropdown:hover,
fieldset[disabled] .btn-orange-dropdown:focus,
fieldset[disabled] .btn-orange-dropdown.focus {
    background-color: #ff9503;
    border-color: #ff9503;
}

.btn-orange-dropdown .badge {
    color: #ff9503;
    background-color: #fff;
}

.btn-header-gray {
    @include button-variant(#fff, #bebbb2, #bebbb2);
    padding: 15px 60px;
    border-radius: 6px;
    text-transform: uppercase;
    box-shadow: 0px 1px 1px rgba(25, 26, 26, 0.75);
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 100px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: #2a2926;
    color: #ddd;
    padding: 20px 0;

    @media (max-width: $screen-xs-max) {
        text-align: center;
        height: auto;

        ul {
            margin-top: 10px;
            padding-left: 0;
        }
    }
}

.footer-navigation {
    list-style: none;
    text-align: right;

    li {
        display: inline-block;
    }

    li.active a {
        color: #ddd;
    }

    a {
        text-transform: uppercase;
        padding: 10px;
        text-decoration: none;
        font-size: 14px;
        color: #ff9503;

        &:hover {
            color: #ddd;
        }
        &:focus {
            color: #ff9503;
        }
    }

    @media (max-width: $screen-xs-max) {
        text-align: center;
    }
}

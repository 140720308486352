h1, h2, h3, h4, h5, h6 {
    margin-top: 30px;
}

h1, h2, h3, h4, h6 {
    text-transform: uppercase;
}

h1, h3, h5 {
    font-weight: 700;
}

::selection {
  background: rgba(255, 149, 3, 0.8);
}
::-moz-selection {
  background: rgba(255, 149, 3, 0.8);
}

.airsys-orange {
    color: #ff9503;
}

body:not(footer).row {
    margin-top: 20px;
    margin-bottom: 30px;
}

em {
    line-height: 30px;
    font-size: 20px;
    font-family: $font-family-serif;
}

.demo-chooser {
    margin-top: 30px;
    margin-bottom: 30px;
    .btn:first-child {
        margin-right: 100px;
    }
}

.airsys-tabs {
    .nav.nav-stacked {
        padding-right: 0;
        li {
            a {
                font-size: 24px;
                text-transform: uppercase;
                color: #b4b4b3;
                font-weight: 700;
                position: relative;
                left: 30px;
                padding-left: 50px;
                h3 {
                    width: 55px;
                    height: 55px;
                    line-height: 50px;
                    color: #ff9503;
                    font-weight: 700;
                    font-size: 35px;
                    border: 1px solid #eee;
                    border-radius: 30px;
                    text-align: center;
                    position: absolute;
                    left: -30px;
                    top: 20px;
                    background: #e5e5e5;
                    background: linear-gradient(45deg, #e5e5e5 0%, #ffffff 100%);
                    box-shadow: 0px 10px 40px -15px rgba(0, 0, 0, 0.67);
                    float: left;
                    display: inline-block;
                    margin: 0;
                }
                p {
                    font-size: 15px;
                    text-transform: none;
                }
            }
            &:hover,
            &.active {
                a {
                    color: #333;
                    background: #efefef;
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    h3 {
                        color: #fff;
                        background: #da8003;
                        background: linear-gradient(45deg, #da8003 0%, #f99203 100%);
                    }
                }
            }
        }
    }
    .tab-content {
        background: #efefef;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        min-height: 450px;
        h3 {
            text-transform: uppercase;
            font-weight: 700;
        }
        h4 {
            font-weight: 700;
            //margin-top: 30px;
            //margin-bottom: 20px;
        }
    }
}

.airsys-dropdown {
    .dropdown-toggle {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        text-align: left;
        i {
            position: relative;
            top: 2px;
        }
    }
    &.open i.fa.fa-chevron-down:before {
        content: ""
    }
    .dropdown-menu {
        width: 100%;
        background-color: #ffa529;
        margin-top: 0;
        border: none;
        li a {
            padding: 10px;
            border-bottom: 2px solid #ff9503;
            &:hover {
                background: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
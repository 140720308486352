section {
    .container {
        padding: 30px;
    }
}

.section-header {
    padding-top: 20px;
    margin-bottom: 20px;
    background: #bdbbb3;
    text-align: center;
    border-bottom: 7px solid #82807b;
    & h2 {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin: 0;
        padding: 10px;
        text-transform: uppercase;
        color: #fff;
        display: inline-block;
        background: #82807b;
    }
}

.section-header-alternative {
    background: white;
}

.section-header-light {
    padding-top: 20px;
    background: #efefef;
    text-align: center;
    border-bottom: 7px solid #fff;
    & h2 {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin: 0;
        padding: 10px;
        text-transform: uppercase;
        color: #82807b;
        display: inline-block;
        background: #fff;
        box-shadow: 0px -15px 35px -20px rgba(0, 0, 0, 0.67);
    }
}

.section-light-gray {
    background: #efefef;
}

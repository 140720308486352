blockquote {
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 20px;
    border: 1px solid #ff9503;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &:before {
        position: absolute;
        font-size: 60px;
        top: 28px;
        left: 36px;
        content: "\201C";
        background: #fff;
        color: #ff9503;
        font-weight: 300;
        width: 30px;
        height: 10px;
        line-height: 0.3;
        padding: 5px;
    }
    cite {
        display: block;
        color: #ff9503;
        margin-top: 20px;
        font-weight: 700;
        font-size: 20px;
        &:before, &:after {
            content: '-';
            padding: 5px;
        }
    }
}
header.master-header {
    width: 100%;
    height: 720px;
    margin-top: -175px;
    padding-top: 175px;
    background-image: url(../images/header-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    border-bottom: 10px solid #222;

    h1 {
        margin-left: 30px;
        margin-top: 200px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 58px;
        font-weight: 700;
        text-shadow: 0 1px 1px rgba(25, 26, 26, 0.75);

        @media (max-width: $screen-xs-max - 100px) {
            margin-top: 0;
            font-size: 48px;
        }
    }

    p {
        margin-top: 30px;
        max-width: 860px;
        padding: 20px 40px 20px 30px;
        background: rgba(227, 149, 38, 0.85);
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
    }

    a {
        margin-top: 10px;
        margin-left: 30px;
    }
}

header.alternative {
    width: 100%;
    min-height: 150px;
    margin-top: -175px;
    padding-top: 175px;
    margin-bottom: 30px;
    background-image: url(../images/header-bg-alternative.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    h1, h1 span {
        margin-left: 30px;
        margin-top: 10px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 58px;
        font-weight: 700;
        text-shadow: 0 1px 1px rgba(25, 26, 26, 0.75);

        @media (max-width: $screen-xs-max - 150px) {
            margin-top: 0;
            margin-left: 0;
            font-size: 24px;
        }
    }
}

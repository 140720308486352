.radio-wrap {
    input[type=radio].airsys-radio {
        position: absolute;
        z-index: -1000;
        left: -1000px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    input[type=radio].airsys-radio + label.airsys-label {
        padding-left: 30px;
        height: 16px;
        display: inline-block;
        line-height: 16px;
        background-repeat: no-repeat;
        background-position: 0 0;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle;
        cursor: pointer;
    }

    input[type=radio].airsys-radio:disabled + label.airsys-label {
        opacity: 0.5;
    }

    input[type=radio].airsys-radio:checked + label.airsys-label {
        background-position: 0 -16px;
    }

    label.airsys-label {
        background-image: url(../images/orange_radio.png);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.panel-pricing {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    @include panel-variant(#ff9503, $panel-default-text, #ff9503, #ff9503);

    h1 {
        font-size: 80px;
        font-weight: 400;
        margin-bottom: 70px;
    }

    h5 {
        margin-top: 0;
    }

    .btn {
        //margin-bottom: 20px;
        font-size: 16px;
    }

    table tr td {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.panel-pricing > .panel-heading {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px;
    //min-height: 80px;

    .panel-title {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
    }
}

.panel-faq {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include panel-variant(rgba(0, 0, 0, 0), $panel-default-text, #FFF6EA, rgba(0, 0, 0, 0));

    .panel-heading.active {
        background-color: #FF9503;
    }

    a.collapsed i.fa.fa-chevron-down:before {
        content: "";
    }
}

.panel-faq > .panel-heading {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px;

    .panel-title {
        text-transform: none;
    }
    //color: #fff;
}

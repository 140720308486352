/* navbar */

.navbar-airsys {
    background-color: transparent;
    border-color: #2a2926;
}

.navbar-airsys .navbar-bottom {
    background-color: #6b9cbd;
}


/* navbar-top */

.navbar-airsys .navbar-top {
    background: rgb(142, 184, 213);
    //background: rgba(119, 119, 119, 0.70);
    height: 43px;
    border-bottom: 10px solid #292825;
    .navbar-nav {
        @media (max-width: $screen-xs-max) {
            position: absolute;
            right: 20px;
            & > li {
                list-style: none;
                float: left;
            }
        }
        padding: 0 10px;
        margin-top: 7px;
        height: 30px;
        display: block;
        background: #292825;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .icon {
            padding: 5px 5px 0 20px;
            color: #ff9503;
            font-size: 18px;
            line-height: 1.4;
        }
    }
    .navbar-nav > li > a {
        &.z-a {
            font-size: 14px;
        }
        border: none;
        font-size: 14px;
        color: #ff9503;
        padding-top: 5px;
        padding-bottom: 0;
        &:hover {
            color: #ffffff;
        }
    }
}

@media (min-width: $screen-md-min) {
    .navbar-airsys .navbar-collapse {
        padding-right: 0;
        position: relative;
        right: -15px;
    }
}


/* title */

.navbar-airsys .navbar-brand {
    color: #fff;
    position: relative;
    top: -65px;
    @media (max-width: $screen-xs-max - 300px) {
        top: -45px;
    }
}

.navbar-airsys .navbar-brand:hover,
.navbar-airsys .navbar-brand:focus {
    color: #838384;
}


/* link */

.navbar-airsys .navbar-nav >:first-child > a {
    border-left: 1px solid #95BBD5;
}

.navbar-airsys .navbar-bottom .navbar-nav > li > a {
    font-weight: 600;
    font-size: 16px;
}

.navbar-airsys .navbar-nav > li > a {
    text-transform: uppercase;
    color: #fff;
    border-right: 1px solid #95BBD5;
}

.navbar-airsys .navbar-nav > li > a:hover,
.navbar-airsys .navbar-nav > li > a:focus {
    color: #ff9503;
    background: none;
}

.navbar-airsys .navbar-nav > .active > a,
.navbar-airsys .navbar-nav > .active > a:hover,
.navbar-airsys .navbar-nav > .active > a:focus {
    color: #ff9503;
    background: none;
}

.navbar-airsys .navbar-nav > .open > a,
.navbar-airsys .navbar-nav > .open > a:hover,
.navbar-airsys .navbar-nav > .open > a:focus {
    color: #ff9503;
    background-color: #5487A9;
}

.navbar-airsys .dropdown-menu {
    min-width: 170px;
    border: none;
    background-color: #6B9CBD;
    & > li > a {
        text-transform: uppercase;
        font-weight: bold;
        padding: 10px 20px;
        color: #FFFFFF;
        &:hover, &:focus {
            color: #FF9503;
            background-color: #5487A9;
        }
    }
}

/* caret */

.navbar-airsys .navbar-nav > .dropdown > a .caret {
    border-top-color: #fff;
    border-bottom-color: #fff;
}

.navbar-airsys .navbar-nav > .dropdown > a:hover .caret,
.navbar-airsys .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #ff9503;
    border-bottom-color: #ff9503;
}

.navbar-airsys .navbar-nav > .open > a .caret,
.navbar-airsys .navbar-nav > .open > a:hover .caret,
.navbar-airsys .navbar-nav > .open > a:focus .caret {
    border-top-color: #ff9503;
    border-bottom-color: #ff9503;
}


/* mobile version */

.navbar-airsys .navbar-toggle {
    border-color: #DDD;
}

.navbar-airsys .navbar-toggle:hover,
.navbar-airsys .navbar-toggle:focus {
    background-color: #DDD;
}

.navbar-airsys .navbar-toggle .icon-bar {
    background-color: #CCC;
}

@media (max-width: 767px) {
    .navbar-airsys .navbar-nav .open .dropdown-menu > li > a {
        color: #777;
    }
    .navbar-airsys .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-airsys .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
    }
}

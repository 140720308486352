.airsys-card {
    margin: 20px 0;
    .airsys-card-icon-head {
        .airsys-card-icon {
            position: relative;
            z-index: 2;
            margin: 0 auto;
            width: 140px;
            height: 140px;
            border-radius: 70px;
            text-align: center;
            background: #e5e5e5;
            background: linear-gradient(45deg, #e5e5e5 0%, #ffffff 100%);
            box-shadow: 0px 10px 40px -15px rgba(0, 0, 0, 0.67);
            i {
                font-size: 90px;
                color: #ff9503;
            }
        }
        .airsys-card-icon-line {
            height: 2px;
            position: absolute;
            left: 0;
            right: 0;
            top: 70px;
            z-index: 1;
            border-top: 2px solid #d1d1d1;
            border-bottom: 2px solid #fff;
            width: 90%;
            margin: 0 auto;
        }
    }
    h4 {
        margin-top: 25px;
        text-align: center;
        text-transform: uppercase;
        &:after {
            content: '';
            display: block;
            border-top: 2px solid #d1d1d1;
            border-bottom: 2px solid #fff;
            margin-top: 10px;
        }
    }
    hr.airsys-card-line {
        border-top: 2px solid #d1d1d1;
        border-bottom: 2px solid #fff;
        margin-top: 30px;
        box-shadow: 0px 10px 40px -15px rgba(0, 0, 0, 0.67);
        &:after {
            content: '';
            width: 80%;
            height: 2px;
            background: transparent;
            margin: 0 auto;
            display: block;
            box-shadow: 0px 20px 46px 1px rgba(0, 0, 0, 0.80);
            border-radius: 50%;
        }
    }
}
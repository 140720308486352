@charset "UTF-8";

@font-face {
  font-family: "airsys-icon-font";
  src:url("../fonts/airsys-icon-font.eot");
  src:url("../fonts/airsys-icon-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/airsys-icon-font.woff") format("woff"),
    url("../fonts/airsys-icon-font.ttf") format("truetype"),
    url("../fonts/airsys-icon-font.svg#airsys-icon-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "airsys-icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="airsys-"]:before,
[class*=" airsys-"]:before {
  font-family: "airsys-icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.airsys-user:before {
  content: "\e001";
}
.airsys-stamp:before {
  content: "\e002";
}
.airsys-briefcase:before {
  content: "\e000";
}
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=latin-ext);
$navbar-height: 70px !default;
$icon-font-path: '../fonts/';
//$border-radius-base: 0px !default;
//$border-radius-large: 0px !default;
//$border-radius-small: 0px !default;
$font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$line-height-base: 1.8;
@import "_bootstrap.scss";
@import "font-awesome.scss";
@import "airsys/navbar.scss";
@import "airsys/header.scss";
@import "airsys/sections.scss";
@import "airsys/buttons.scss";
@import "airsys/cards.scss";
@import "airsys/tabs.scss";
@import "airsys/lists.scss";
@import "airsys/quotes.scss";
@import "airsys/typography.scss";
@import "airsys/icons.scss";
@import "airsys/panels.scss";
@import "airsys/maps.scss";
@import "airsys/dropdowns.scss";
@import "airsys/modals.scss";
@import "airsys/tables.scss";
@import "airsys/footer.scss";
@import "airsys/forms.scss";
@import "airsys/carousel.scss";
@import "airsys/checkbox.scss";
@import "airsys/radio.scss";
@import "airsys/spacers.scss";

.carousel-control.right {
    background: none;
}

.carousel-control.left {
    background: none;
}

.carousel-title {
    position: absolute;
    left: 15%;
    right: 15%;
    top: 10px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

@media screen and (min-width: $screen-sm-min) {
    .carousel-title {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }
}
